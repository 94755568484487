import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { CustomComponentDetail, Position } from 'src/app/utils/constant';

@Injectable({
  providedIn: 'root'
})
export class CustomSidenavService {
  
  constructor() { }

  private _componentPortal$ = new BehaviorSubject<CustomComponentDetail | null>(null);
  getComponentPortal$(): Observable<CustomComponentDetail | null> {
    return this._componentPortal$.asObservable();
  }
  setComponentPortal(component: ComponentType<any> | null, position?: Position) {
    let customComponentObj: CustomComponentDetail = {
      ComponentPortal : new ComponentPortal<any>(component!),
      Position: position
    }
    this._componentPortal$.next(customComponentObj);
  }

}
