import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class NewJobService {

  quotationId$ = new BehaviorSubject<number>(0);

  constructor(private http: HttpService, private storageService: StorageService) { }

  fixZJobDetails:any = {
    service_id: 0,
    subservice_id: 0,
    description: '',
    address_id: 0,
    start_date: '',
    start_time: '',
    request_type: 'FIXZ',
    space_type: '',
    additional_data: {},
    title: ''    
  };

  mediaData: any = {
    image: null,
    video: null
  }

  acceptedOfferObj: any = {
    quotation_id: '',
    status: '',
    transaction_id: ''
  }

  // convertNestedArrayToString(arr: any) {
  //   return arr.map((item: any) => {
  //     if (typeof item === 'object' && item !== null) {
  //       return this.convertNestedArrayToString(item);
  //     }
  //     return item;
  //   }).join(', ');
  // }

  

  setData(key: string, value: any): any {
    this.fixZJobDetails[`${key}`] = value;
    this.storeData('newJobData', this.fixZJobDetails);
  }

  setMediaData(key: string, value: any) {
    console.log(key,value);
    this.mediaData[`${key}`] = value;
    // this.storeData('newJobMediaData', this.mediaData);
  }

  storeData (key:string, value: any) {
    this.storageService.setItem('session', key, value);
  }

  postServiceRequest(data: any) : Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/user/post-repair-request`, data);
  }


  getServiceDetail(serviceKey: string): Observable<any> {
      return this.http.get(`${environment.apiBaseUrl}/get-service-config/${serviceKey}`);
  }

  cancelJob(data: any): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/user/update-request-status`, data);
  }

  acceptOrRejectQuotation(): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/user/accept-reject-quotation`, this.acceptedOfferObj)
  }
 

}
