import { Component, OnInit  } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit{
  phone: string = '';
  showOTPVerification: boolean = false;
  showLogin: boolean = true;
  constructor(public authService: AuthService, private route: ActivatedRoute, private router: Router, private toastr: ToasterService) {}
  ngOnInit(): void {
    this.showOTPVerification = false;    
  }

 
  getUserPhone(phone:string){
    this.phone = phone;
  }
  
  userLoggedIn(event: any){
    if(event){
      this.toastr.success('User Logged In Successfully', 'Success');
      const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
      this.router.navigateByUrl(returnUrl);
    }
  }
   
  
}
