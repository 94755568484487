import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LoginGuard } from './guards/login.guard';
import { AuthGuard } from './guards/auth.guard';
import { ServiceSelectionComponent } from './pages/service-selection/service-selection.component';
import { constants } from './utils/constant';
import { ServiceDetailService } from './services/resolver/service-detail.service';

const loadTable = () => import('./pages/table-layout/table-layout.component').then(c => c.TableLayoutComponent);
const tableRoutes = constants.jobRoutes;

const routes: Routes = [
  {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
  {path: '', redirectTo:'services', pathMatch: 'full'},
  {path: 'services', canActivate: [AuthGuard], children: [
    {path: '', component: ServiceSelectionComponent},
    {path: 'detail', loadComponent: () => import('./pages/service-detail/service-detail.component').then(c => c.ServiceDetailComponent), resolve: { data: ServiceDetailService }}
  ]},
  {path: `${tableRoutes[0].url}`, loadComponent: () => import('./pages/table-layout/table-layout.component').then(c => c.TableLayoutComponent), canActivate: [AuthGuard] , title: `${tableRoutes[0].title}`},
  {path: `${tableRoutes[1].url}`, loadComponent: () => import('./pages/table-layout/table-layout.component').then(c => c.TableLayoutComponent), canActivate: [AuthGuard] , title: tableRoutes[1].title},
  {path: `${tableRoutes[2].url}`, loadComponent: () => import('./pages/table-layout/table-layout.component').then(c => c.TableLayoutComponent), canActivate: [AuthGuard] , title: tableRoutes[2].title},
  {path: `${tableRoutes[3].url}`, loadComponent: () => import('./pages/table-layout/table-layout.component').then(c => c.TableLayoutComponent), canActivate: [AuthGuard] , title: tableRoutes[3].title},

  {path: 'dispute-request', loadComponent: () => import('./pages/table-layout/table-layout.component').then(c => c.TableLayoutComponent), canActivate: [AuthGuard] , title: 'My Dispute Request'},
  {path: 'manage-address', loadComponent: () => import('./pages/manage-address/manage-address.component').then(c => c.ManageAddressComponent), canActivate: [AuthGuard]},
  {path: 'job-detail', loadComponent: () => import('./pages/job-detail/job-detail.component').then(c => c.JobDetailComponent), canActivate: [AuthGuard]},
  {path: 'profile', loadComponent: () => import('./pages/profile/profile.component').then(c => c.ProfileComponent), canActivate: [AuthGuard]},
  {path: 'payment-success', canActivate: [AuthGuard], loadComponent: () => import('./pages/payment-success/payment-success.component').then(c => c.PaymentTransactionComponent)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
